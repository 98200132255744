<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card tile flat v-if="loaderdata === false">
                    <v-toolbar color="" elevation="0" class="primaryAccent">
                        <v-toolbar-title>{{ reois.enterprise !== null? reois.enterprise.name: "Masked enterprise" }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items class="align-center">
                            {{ reois.title }}
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Summary of the offer</v-list-item-title>
                            <v-list-item-subtitle>
                                <v-card class="subsecondary rounded-lg">
                                    <v-card-text class="d-flex flex-column">
                                        <div class="d-flex flex-row">
                                            <span class="text-body-1">Tile</span>
                                            <v-spacer></v-spacer>
                                        </div>
                                        <span>{{ reois.title }}</span>
                                    </v-card-text>
                                    <v-card-text class="d-flex flex-column">
                                        <span class="text-body-1">Object</span>
                                        <span>{{ reois.object }}</span>
                                    </v-card-text>
                                </v-card>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list two-line>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle v-if="(loaderdata === false) && (myEnterprise.isExist === false)">
                                    <span class="error--text">No enterprise yet, Only enterprise can reply</span>
                                </v-list-item-subtitle>
                                <v-list-item-title>Select company</v-list-item-title>
                                <v-list-item-subtitle>
                                    <v-select v-model="selectedEnterprise" dense outlined hide-details
                                        :items="myEnterprise.data" item-value="id" item-text="name" return-object>
                                    </v-select>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Write to the company about this offer</v-list-item-title>
                                <v-list-item-subtitle>
                                    <vue-editor v-model="rfpComment" outlined dense class="text-left mb-3" label="Body"
                                        placeholder="Description of your acceptation">
                                    </vue-editor>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list three-line subheader>
                        <v-subheader>Attach others documents</v-subheader>
                        <v-list-item>
                            <v-list-item-action>

                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>#</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" class="px-4" outlined @click="dialog = false"> Cancel
                        </v-btn>
                        <v-btn class="secondary px-4" @click="sentReoiResponse" v-if="myEnterprise.isExist === true">
                            Submit
                        </v-btn>
                    </v-card-actions>
                </v-card>
                <v-card v-else>
                    <v-skeleton-loader type="card-avatar, article, article, actions"></v-skeleton-loader>
                </v-card>
            </v-col>
        </v-row>
    </v-container>

</template>
<script>
import { VueEditor } from "vue2-editor";
import { mdiClose } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'

export default {
    data() {
        return {
            reois: {},
            dialog: false,
            rfpComment: '',
            loadingChecked: true,
            loaderdata: true,
            myEnterprise: {
                isExist: false,
                data: []
            },
            selectedEnterprise: {}
        }
    },
    components: {
        VueEditor
    },
    methods: {
        sentReoiResponse() {
            var bodyFormData = {
                "rfpenterprise": {
                    "rfp.id": this.reois.id,
                    "enterprise.id": this.selectedEnterprise.id,
                    "comment": this.rfpComment,
                }
            }
            Drequest.api(`rfpenterprise.reply`)
                .data(bodyFormData)
                .raw((response) => {
                    if (response.success === true) {
                        this.$fire({
                            type: "success",
                            text: response.detail,
                            timer: 3000
                        })
                        this.loadingDatas = false
                        this.$router.push({ name: 'proposal.list' })
                    } else {
                        this.$fire({
                            type: "error",
                            text: "Error occured, please retry later..!",
                            timer: 3000
                        })
                        this.loadingDatas = false
                    }
                })
                .catch((err) => {
                    console.log("err")
                    this.loadingDatas = false
                })
            this.dialog = false
        }
    },
    beforeMount() {
        this.loaderdata = true
        Drequest.api(`lazyloading.enterprise?dfilters=on&user.id:eq=${this.$store.getters.getUser.id}`).get((response) => {
            if (response.success === true) {
                if (response.nb_element > 0) {
                    this.myEnterprise.isExist = true
                    response.listEntity.forEach(element => {
                        this.myEnterprise.data.push({ id: element.id, name: element.name })
                    });
                    this.selectedEnterprise = {
                        id: this.myEnterprise.data[0].id,
                        name: this.myEnterprise.data[0].name
                    }
                }
            }
            else {
                this.myEnterprise.isExist = false
            }
        }).catch((err) => { });
        Drequest.api(`lazyloading.rfp?dfilters=on&id:eq=${this.$route.params.offer_id}`)
            .get((response) => {
                if (response.success === true) {
                    this.reois = response.listEntity[0]
                    console.log(response.listEntity)
                    this.loaderdata = false
                }
                else {
                    this.loaderdata = false
                }
            })
            .catch((err) => {
                this.loaderdata = false
            });
    },
    setup(props) {
        const icons = { mdiClose }
        return {
            icons
        }
    }
}
</script>